<template>
  <div>
    <h2>Start New Conference Video</h2>
    <div class="card">
      <div class="px-3 py-2">
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Title</label>
            <input type="text" placeholder="Title" v-model="video.title" required class="form-control">
          </div>
          <div class="form-group">
            <label>Video URL</label>
            <input type="text" placeholder="Video URL" v-model="video.video_url" required class="form-control">
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Start</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: {}
    }
  },

  methods: {
    submit() {
      this.$axios.post('/api/v1/moderator/conference', this.video).then(response => {
        this.$router.push({ name: 'moderator.conference.show', params: { id: response.data.id }})
      })
    }
  }
}
</script>